.jobBoxes
  h2
    font-family $base-font-family
    font-weight normal
    font-size 1.828rem
    color #a7af39
    letter-spacing -1px
    line-height 1.5rem
    line-height 3rem
    margin-top 1.5rem
    margin-bottom 0
    margin-left 5px
  &__jobs
    display flex
    flex-wrap wrap
    flex-direction row
    justify-content space-between
    width $L_width
    margin 0 auto
    @media screen and (max-width: $L_width)
      width auto
    @media screen and (max-width: 480px)  
      justify-content center
  &__job
    display inline-block
    margin 0 20px 50px
    font-family $base-font-family
    font-weight normal
    -webkit-transition all 250ms
    transition all 250ms
    padding 4px
    list-style none
    @media screen and (min-width: $S_width)
      width 100%
    @media screen and (min-width: 480px)
      width 40%
    @media screen and (min-width: $M_width)
      min-width 257px
      width auto 
    a
      text-decoration none
      color #000
  &__backgroundImage
    width 100%
    display block
    background-position 37% 50%
    background-repeat no-repeat
    background-size cover
    margin-bottom 3px
    &:before
      content ""
      display block
      padding-top 66.666666666667%
  &__firstRow
    font-weight normal
    color #9E182F
    padding 4px 4px 0
    font-size 18px
  &__secondRow
    font-weight normal
    opacity .5
    padding 0 4px