.media
  &__container
    display flex
    position relative
    padding-top $abstand_m
    padding-bottom $abstand_m

    &--withStoerer
      margin-top 1.7rem
      padding-top $abstand_l
      padding-bottom $abstand_l
      @media screen and (min-width $M_width)
        margin-top 0
        padding-top $abstand_m
        padding-bottom $abstand_m
    .media__item
      margin 0 auto
      figure, video, img, source, picture
        display flex
        text-align center
        padding 0
        margin 0 auto
        width auto
        height 100%
        max-width 100%
        max-height 100%
      figcaption
        max-width 100%
      &--fullwidth
        width 100%
        height auto
        object-fit cover

  &__stoerer
    position absolute
    top -2rem
    right 0
    text-align center
    align-content center
    padding-top 0.88rem
    padding-bottom 0.88rem
    padding-left 0.5rem
    padding-right 0.5rem
    aspect-ratio 1/1
    color $neuland_weiss
    background-color $neuland_rotorange
    border-radius 4rem
    fontSizeMedium()
    &:hover
      background-color $neuland_rotorange_hover
      color $neuland_weiss
    @media screen and (min-width $M_width)
      fontSizeStandard()
      top -2.5rem
      padding-top 1.1rem
      padding-bottom 1rem
      padding-left 0.6rem
      padding-right 0.6rem