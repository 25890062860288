.ctaKontaktperson
  .constrainedContent
    margin 0
    padding 0

  &__container
    display grid
    grid-template-areas\
      "title title   title"\
      ".     contact ."\
      ".     image   ."
    grid-template-columns 1fr 4fr 1fr
    grid-template-rows auto
  &__title
    grid-area title
    margin-bottom $abstand_s
  &__contact
    grid-area contact        
  &__image
    grid-area image
    @media screen and (min-width $M_width)
      img
        max-height 12rem        

  &__ansprechpartner
    color #000
    fontSizeMedium()
    display block
  &__name
    color $neuland_rotorange
    fontSizeMedium()
    display block
  &__jobtitle
    color #000
    opacity 0.5
    fontSizeMedium()
    display block
  &__button
    display block
    margin-top 1rem

  @media screen and (min-width $M_width)
    &__container
      grid-gap $abstand_xs   
      grid-template-areas\
        "title title   title title "\
        ".     contact image ."      
      grid-template-columns 1fr 2fr 2fr 1fr

  @media screen and (min-width $L_width)
    &__container
      grid-gap $abstand_xs      
      grid-template-areas\
        "title title   title title "\
        ".     contact image ."
      grid-template-columns 1fr 1fr 1fr 1fr
      grid-template-rows auto auto
      retina({grid-template-rows: auto auto})  

  @media screen and (max-width $L_width)
    &__contact
      margin-top 1rem   
    &__image
      margin-top 1rem
      margin-bottom 1rem

.formattedText .ctaKontaktperson
    &__title
      h2, p
        text-align center
        color $neuland_rotorange
      p
        fontSizeL()     

.gruen
  .formattedText .ctaKontaktperson
    &__title
      h2, p
        color $neuland_gruen
    &__name
      color $neuland_gruen
