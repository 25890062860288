.content
  
  &__section
    box-sizing border-box
    margin 0 auto
    max-width 960px
    padding 0 20px
    position relative
    
    p
      max-width 600px
      margin 0 auto