.cookieConsent
  max-width 100%
  display block
  position fixed
  bottom 0
  left 0
  right 0
  background-color $neuland_weiss
  width 100%
  // box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.12), 0 -1px 2px rgba(0, 0, 0, 0.24);
  border-top 1px solid $neuland_schwarz
  border-right 1px solid $neuland_schwarz
  border-left 1px solid $neuland_schwarz
  z-index 9999
  &__content
    display flex
    margin 0 auto
    padding-left $abstand_xs
    padding-right $abstand_xxs
    padding-top $abstand_xxs
    padding-bottom $abstand_xxs
    font-family $base-font-family
    flex-wrap nowrap
    flex-direction row
    justify-content space-between
    @media screen and (max-width: $L_width)
      padding 10px
    @media screen and (max-width: $M_width)
      display block
  &__text
    display flex
    justify-content space-between
    align-self center
  &__accept
    display flex
    justify-content space-between
    align-self center
    @media screen and (max-width: $M_width)
      padding-top 10px
    &__button
      box-sizing border-box
      display inline-block
      border 1px solid rgba(66,66,66,0.3)
      color $neuland_rotorange
      padding 10px
      -webkit-transition max-height 0.6s ease-out
      -moz-transition max-height 0.6s ease-out
      -o-transition max-height 0.6s ease-out
      transition max-height 0.6s ease-out
      text-decoration none
      &:hover
        background-color $neuland_rotorange
        color $neuland_weiss
  &__more
    display flex
    justify-content space-between
    align-self center
    &__button
      margin-right: 10px
      box-sizing border-box
      display inline-block
      border 1px solid rgba(66,66,66,0.3)
      color rgba(66,66,66,0.6)
      padding 10px
      -webkit-transition max-height 0.6s ease-out
      -moz-transition max-height 0.6s ease-out
      -o-transition max-height 0.6s ease-out
      transition max-height 0.6s ease-out
      text-decoration none
      &:hover
        background-color $neuland_rotorange
        color $neuland_weiss