.blogSection
  padding-top $abstand_s
  padding-bottom 0

  .blogEntry
    &__title, h3.blogEntry__title
      fontSizeSmall()
      margin-bottom 0
      min-height unset
    &__excerpt, &__readmore
      display none
  @media screen and (min-width: $S_width_horizontal)
    &__entries
      display flex
      flex-wrap wrap
      column-gap $abstand_xs
    &__entry
      flex-basis "calc(50% - (%s * 1/2))" % $abstand_xs
  @media screen and (min-width: $L_width)
    &__entry
      flex-basis "calc(33.33% - (%s * 2/3))" % $abstand_xs
  @media screen and (min-width: $XL_width)
    &__entry
      flex-basis "calc(25% - (%s * 3/4))" % $abstand_xs

  &--noResult
    padding $abstand_m 0

  &__goToBlog
    fontSizeSmall()
    text-decoration underline

.blogSection--single
  .blogSection
    &__entry
      @media screen and (min-width: $M_width)
        width 100%
  .blogEntry
    @media screen and (min-width: $M_width)
      display flex
      justify-content space-between
      &__image, &__content
        width calc(50% - 10px)
