.contactForm
  &__title
    fontSizeXL()
    margin-bottom $abstand_m
  &__form
    display flex
    flex-wrap wrap
    justify-content space-between
    margin-top $abstand_m
    margin-bottom 0.6rem
    @media (min-width: $L_width)
      width "calc(66.66% - (%s * 1/3))" % $abstand_xs
    @media (min-width $XL_width)
      margin-top $abstand_s
      width 100%

    input
      fontSizeSmall()
      padding 0.3rem
      margin-bottom $abstand_xs
      @media screen and (max-width: 499px)
        width 100%
        margin-rigth 0.625rem
      @media (min-width: $S_width_horizontal)
        width "calc(50% - (%s * 1/2))" % $abstand_xs
      @media (min-width: $XL_width)
        width "calc(50% - (%s * 1/2))" % $abstand_xs

    textarea
      width 100%
      padding 0.3rem
      height 10rem
      fontSizeSmall()
      margin-bottom 1rem

    button
      fontSizeSmall()

  &__website
    position absolute
    left -7234px  
