.centeredContentGrid
  strong
    font-weight 400
    color $neuland_rotorange
  .constrainedContent
    margin 0
    padding 0

  &.formattedText .centeredContentGrid__container
    ul
      padding-left 1em
    ol
      padding-left 0.3em
      li:before
        color $neuland_schwarz

  &__container
    display grid
    grid-gap $abstand_xs   
    grid-template-areas\
      "ersterAbsatz  ersterAbsatz  ersterAbsatz  ersterAbsatz"\
      ".             links         links         ."\
      "zweiterAbsatz zweiterAbsatz zweiterAbsatz zweiterAbsatz"\
      ".             rechts        rechts        ."\
      "dritterAbsatz dritterAbsatz dritterAbsatz dritterAbsatz"
    grid-template-columns  1fr 4fr 4fr 1fr
    grid-template-rows auto

  &__ersterAbsatz
    grid-area ersterAbsatz
    strong
      display block
  &__zweiterAbsatz
    grid-area zweiterAbsatz
    strong
      display block
  &__dritterAbsatz
    grid-area dritterAbsatz
    strong
      display block
  &__links
    grid-area links
    display grid
    grid-gap $abstand_xs
    grid-template-areas\
      "linksZahl linksText"
    grid-template-rows auto
    grid-template-columns  1fr 2fr
    margin-top $abstand_s
    margin-bottom $abstand_m
  &__rechts
    grid-area rechts    
    display grid
    grid-gap $abstand_xs
    grid-template-areas\
      "rechtsZahl rechtsText"
    grid-template-columns 1fr 2fr
    grid-template-rows auto   
    margin-top $abstand_s
    margin-bottom $abstand_m
  &__linksZahl, &__rechtsZahl, &__links &__linksZahl, &__rechts &__rechtsZahl
    color $neuland_rotorange
    margin-bottom: 0.4rem
    text-align right
  &__linksText, &__rechtsText
    color $neuland_rotorange
    fontSizeSmall()

  @media screen and (min-width $L_width)
    &__container
      display grid
      grid-gap $abstand_xs   
      grid-template-areas\
        "links ersterAbsatz  ."\
        ".     zweiterAbsatz rechts"\
        ".     dritterAbsatz ."
      grid-template-columns 1fr 2fr 1fr
      grid-template-rows auto     
    &__links
      grid-gap $abstand_xs
      grid-template-areas\
        "linksZahl"\
        "linksText"
      grid-template-rows 2.5rem 
      grid-template-columns 1fr
      margin-top 0
      margin-bottom 0
    &__rechts
      grid-gap $abstand_xs
      grid-template-areas\
        "rechtsZahl"\
        "rechtsText"        
      grid-template-rows 2.5rem
      grid-template-columns 1fr    
      margin-top 0
      margin-bottom 0
    &__linksZahl, &__rechtsZahl, &__links &__linksZahl, &__rechts &__rechtsZahl
      text-align left


.gruen
  .centeredContentGrid
    strong
      color $neuland_gruen
    &__linksZahl, &__rechtsZahl, &__links &__linksZahl, &__rechts &__rechtsZahl
      color $neuland_gruen
    &__linksText, &__rechtsText
      color $neuland_gruen