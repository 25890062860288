.button
  &--submit, &--primary
    display inline-block
    border none
    border-radius 100px 0 0 100px
    padding 2px 1.2em 0 1.2em
    retina({ padding: 1px 1.2em })
    fontSizeSmall()
    text-align center
    text-decoration none
    cursor pointer
    background-color $neuland_rotorange
    color $neuland_weiss
    &:hover
      background-color $neuland_rotorange_hover
      color $neuland_weiss
  &--left-aligned
    border-radius: 0 100px 100px 0

  &--secondary
    display inline-block
    border none
    padding 2px 1.2em
    fontSizeSmall()
    text-align center
    text-decoration none
    cursor pointer
    background-color $neuland_offwhite
    color $neuland_schwarz
    &:hover
      background-color $neuland_offwhite_hover
      color $neuland_schwarz

.gruen
  .button    
    &--submit, &--primary
      background-color $neuland_gruen
      &:hover
        background-color $neuland_gruen_hover