.schedule
  display flex
  flex-wrap wrap
  max-width 1800px
  width 100%
  overflow hidden
  margin 0 auto 4rem

  &__mapContainer
    flex-basis 50%
    margin 0 auto
    @media (max-width: 450px)
      height 380px

    @media (max-width: 1000px)
      flex-basis 100%

    @media (min-width: 1001px) and (max-width: 1400px)
      flex-basis 40%

  &__map
    @media (max-width: 450px)
      position relative
      min-width 450px
      left 50%
      margin-left -228px

  &__tableContainer
    @media (max-width: 1000px)
      flex-basis 100%
      max-width 100%

    @media (min-width: 1001px) and (max-width: 1400px)
      flex-basis 60%
      max-width 60%

    @media (min-width: 1401px)
      padding 0
      flex-basis 50%
      max-width 50%

  &__table
    width 100%
    margin-top 1.5rem
    .scheduleEntry
      display flex
      width 100%
      @media (max-width: 500px)
        flex-direction column
      &:nth-child(even)
        background-color #f9f9ef
      &__timerArrow
        margin-bottom -8px
        min-width 24px
        margin-right 4px
        @media (min-width: 500px)
          margin-top 2px
      &__timeBlock
        width 23%
        padding 8px 0.5rem 0.5rem 0rem !important
      &__activity
        width 76%
        flex-direction column
        padding 5px 0.5rem 0.5rem 0rem !important
      &__timeBlock, &__activity
        display flex
        @media (max-width: 500px)
          width 100%
          justify-content center
          padding 0.2rem 0.5rem 0.5rem 0.5rem !important
        &:first-child
          white-space nowrap
        &:last-child
          @media (max-width: 500px)
            padding-bottom 1rem!important
        p
          margin 0
      
    &--dark
      .scheduleEntry
        &:nth-child(even)
          background-color #272727



  &__activityLocation
    width 1.4rem
    height 1.4rem
    display inline-block
    color #000
    border-radius 50%
    background-color $neuland_green
    text-align center
    position absolute
    font-size 1rem
    right 0
    top $abstand_xxs
    line-height 1.5rem

    &--inline
      position relative
      right 0

  &__activityTitle
    padding 1px 2.5rem 0 0
    position relative
    display inline-block
    line-height 1.5rem

  .icon__arrowright
    width 24px
    height 24px
    fill #fff

.scheduleEntry
  .icon__arrowright
    display none
  &--active
    outline 4px solid $neuland_rotorange
    outline-offset -4px
    // border 4px solid $neuland_rotorange
    @media (max-width: 500px)
      border-right none
      border-left none
    .icon__arrowright
      display inline

.main--tvMode
  .schedule
    display none
  
.main--onlyTimetable
  min-height 100vh