@import "imageSource"

.blogEntry
  &__meta
    fontSizeSmall()
    margin-bottom $abstand_xxs
    color $neuland_gray
  &__image
    justify-content flex-start
    position relative
    display block
    &--highlighted
      width 100%
      justify-content center
      margin 0 auto $abstand_xxs
      @media screen and (max-width: $M_width)
        width 33.33% - $abstand_xxs
        justify-content flex-start
        margin-right 0
        margin-left 0
    img
      display block
      width 100%
      height 10rem
      object-fit cover

  &__imagesource
    @extend .imageSource
  &__title, .blogEntry__title
    display block
    margin-top $abstand_s
    margin-bottom $abstand_s
    fontSizeL()

    @media screen and (min-width: $M_width)
      min-height 3.1rem
    a
      display inline-block
      color $neuland_schwarz
      text-decoration none
      display -webkit-box
      -webkit-box-orient vertical
      -webkit-line-clamp 3
      overflow hidden
      text-overflow ellipsis
      &:hover
        color $neuland_rotorange
  &__content
    padding-bottom $abstand_l
    padding-left 0
    @media screen and (max-width: $M_width -1)
      width 100%
      justify-content center
    &--highlighted
      width calc(100% - $abstand_s)
      justify-content center
      padding 0 $abstand_xxs
      text-align center
      @media screen and (max-width: $M_width -1)
        width calc(66.66666666% - $abstand_s)
        justify-content flex-start
  &__excerpt
    margin-bottom $abstand_xxs
    fontSizeSmall()
  &__readmore
    a
      fontSizeSmall()
      box-sizing border-box
      display block
      text-decoration underline
      &:hover
        color $neuland_rotorange
        text-decoration underline


  /*@media screen and (min-width: $S_width_horizontal) and (max-width: $M_width)
    display flex
    justify-content space-between
    &__image
      width calc(40% - 10px)
      img
        height 150px
    &__content
      width calc(60% - 10px)
      h3.blogEntry__title
        margin-top 0*/

.blogEntry--featuredEntry
  width 100%
  justify-content space-between
  @media screen and (min-width: $M_width)
    display flex
    align-items flex-start
    flex-wrap wrap
  .blogEntry__title
    @media screen and (min-width: $M_width)
      margin-top 0
  .blogEntry__image
    @media screen and (min-width: $M_width)
      width calc(50% - 10px)
  .blogEntry__content
    @media screen and (min-width: $M_width)
      width calc(50% - 10px)