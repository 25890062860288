.people
  width $L_width
  padding-top 30px
  @media screen and (max-width: $L_width)
    width $M_width
  @media screen and (max-width: $M_width)
    width 100%
  @media screen and (min-width $L_width)
    width $L_width
  margin 0 auto
  &__headline
    font-family $base-font-family
    font-weight normal
    color $neuland_green
    font-size 30px
    margin-left 20px
    padding-top 10px
    padding-bottom 10px
.employees, .founders
  display flex
  flex-wrap wrap
  flex-direction row
  justify-content flex-start
  width $L_width
  //margin 0 auto
  @media screen and (max-width: $L_width)
    width auto
  @media screen and (max-width: 480px)  
    justify-content center

.employee
  display inline-block
  margin 0 20px 50px
  font-family $base-font-family
  font-weight normal
  -webkit-transition all 250ms
  transition all 250ms
  padding 4px
  @media screen and (min-width: $S_width)
    width 100%
  @media screen and (min-width: 480px)
    width 40%
  @media screen and (min-width: $M_width)
    width 27%
  &__image
    display block
  &__name
    font-weight normal
    color #9E182F
    padding 4px 4px 0
    font-size 16px
    &--job
      color $neuland_green
      font-weight bold
  &__position
    font-weight normal
    opacity .5
    padding 0 4px
    display none
  img
    margin-bottom 3px
    @media screen and (max-width: $M_width)
      width 100%
 
.filler
  &__job
    
    display inline-block
    margin 0 20px 50px
    font-family $base-font-family
    font-weight normal
    -webkit-transition all 250ms
    transition all 250ms
    padding 4px
    list-style none
    @media screen and (min-width: $S_width)
      width 100%
    @media screen and (min-width: 480px)
      width 40%
    @media screen and (min-width: $M_width)
      min-width 257px
      width auto 
    a
      text-decoration none
      color #000
  &__backgroundImage
    width 100%
    display block
    background-position 37% 50%
    background-repeat no-repeat
    background-size cover
    margin-bottom 3px
    &:before
      content ""
      display block
      padding-top 66.666666666667%
  &__firstRow
    font-weight normal
    color #9E182F
    padding 4px 4px 0
    font-size 18px
  &__secondRow
    font-weight normal
    opacity .5
    padding 0 4px

.jobs
  display inline-block
  margin 5px 5px 30px
  font-family TheSans-Bold
  font-weight normal
  width 249px
  height 198px
  vertical-align top
  -webkit-transition all 250ms
  transition all 250ms
  box-shadow 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)
  padding 4px
  background-color white
  &:hover
    box-shadow 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23)
    -webkit-transform translateY(-5px)
    -ms-transform translateY(-5px)
    transform translateY(-5px)
  a
    color #9E182F
    color white
    text-decoration none
    display block
    padding 72px 0
  p
    font-family "CCCodeMonkeyVariable W01419732"
    margin-top 9px
    font-size 15px
    color #44422D
  &__job
    display flex
    align-items center
    justify-content center
    height 166px
    background-color #9E182F
    box-shadow inset 0px 0px 50px 0px rgba(0,0,0,0.26)