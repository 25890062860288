.cta
  .constrainedContent
    margin 0
    padding 0

  &__container
    display grid
    grid-template-areas\
      "intro"\
      "image"\
      "content"\
      "contact";
    grid-template-columns 1fr
    grid-template-rows auto;
  &__intro
    grid-area intro
  &__intro + &__content .cta__logo
    margin-top $abstand_xxs
  &__logo
    margin-bottom $abstand_m
  &__image
    grid-area image
    justify-self left
    padding-top $abstand_s
    margin-bottom $abstand_xxs
    width 2rem
  &__content
    grid-area content
  &__contact
    grid-area contact
    margin-top $abstand_xxs

  @media screen and (min-width $L_width)
    &__container
      grid-gap $abstand_xs      
      grid-template-areas\
        ".     intro   . "\
        "image content ."\
        ".     contact ."
      grid-template-columns 1fr 2fr 1fr
      grid-template-rows auto
      retina({grid-template-rows: auto})    
    &__image
      justify-self right
      padding-top 0.5rem
    &__intro
      margin-bottom $abstand_xs
    &__contact
      margin-top 0
    
.formattedText .cta
    &__intro
      p
        margin-bottom 0
    &__content
      a
        text-decoration underline        
      a:hover
        text-decoration none