.footer
  sideSpacing()
  padding-top $abstand_l
  padding-bottom $abstand_xl
  background-color $neuland_offwhite
  fontSizeMedium()
  @media screen and (min-width $L_width)
    padding-top $abstand_m

  &__container
    display grid
    grid-template-areas\
      "logo"\
      "imprint"\
      "about"
    grid-template-columns 1fr
    grid-template-rows auto;
    @media screen and (min-width $L_width)
      grid-template-areas\
      "logo about"\
      "imprint about"
      grid-gap $abstand_xs
      grid-template-columns 1fr 2fr
      grid-template-rows 2.25rem auto
      retina({grid-template-rows: 2.65rem auto})
    @media screen and (min-width $XXL_width) // TODO könnte auch auf xl --- nur zum testen jetzt höher
      grid-template-areas "logo about imprint"
      grid-template-columns 1fr 2fr 1fr
      grid-template-rows auto
      retina(@block{
          grid-template-columns: 1fr 2fr;
          grid-template-rows: 2.65rem auto
          grid-template-areas:\
            "logo about"\
            "imprint about";
        })
  &__logo
    grid-area logo
  &__about
    grid-area about
  &__imprint
    grid-area imprint
    padding-right 0
    margin-bottom $abstand_m
    @media screen and (min-width $S_width_horizontal) and (max-width $L_width - 1)
      display flex
      justify-content space-between
      margin-bottom 0
      .footer__address
        padding-right $abstand_xs
      .footer__links
        text-align right

  @media screen and (min-width $L_width)
    &__logo
      retina({margin-top: 0.45rem})
    &__imprint
      padding-right $abstand_s
      fontSizeSmall()
      retina({font-size: 0.7rem}); //TODO: hier klappt was mit mixin + retina-function nicht

  @media screen and (min-width $XXL_width) // TODO könnte auch auf xl --- nur zum testen jetzt höher
    &__logo
      retina({margin-top: 0.45rem})
    &__about
      padding-right $abstand_m
    &__imprint
      margin-top 1.2rem
      padding-right 0
      retina({margin-top: 0})

  &__heading
    display block
    color $neuland_rotorange
    font-weight 400
    align-content flex-end
    height 2.25rem
    retina({height: 2.55rem})

  &__about.formattedText
    p, div, li
      fontSizeMedium()

  a
    color #000
    display block
    text-decoration underline

  &__logo .icon--neulandlogo
    fill #ff5a55
    margin-bottom $abstand_xs
    width 120px
    retina({ width: 140px })
    @media screen and (min-width: $M_width)
      width 148px
    @media screen and (min-width: $L_width)
      retina({ width: 188px })

  &__mail
    margin-bottom $abstand_l

.gruen
  .footer
    &__heading
      color $neuland_gruen
    &__logoImg   
      filter invert(28%) sepia(53%) saturate(7326%) hue-rotate(145deg) brightness(99%) contrast(96%)
    &__logo .icon--neulandlogo
      fill $neuland_gruen