.projects
  display flex
  flex-wrap wrap
  width $L_width + 50px
  @media screen and (max-width: $L_width)
    width $M_width
  @media screen and (max-width: $M_width)
    width 100%
  margin 0 auto 7em
  &__project
    width 25%
    box-sizing border-box
    padding 25px 50px
    vertical-align middle
    list-style none
    // display inline-block
    //text-align center
    img
      max-height 64px
    @media screen and (max-width: $L_width)
      width 33.333333%
    @media screen and (max-width: $M_width)
      width 50% 
      padding 20px
      
    &--small
      width 20%
      @media screen and (max-width: $L_width)
        width 33.333333%
      @media screen and (max-width: $M_width)
        width 50%
        padding 20px