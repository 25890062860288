.pageIntro
  fontSizeStandard()
  text-align left
  margin-top $abstand_xs
  margin-bottom $abstand_xs
  @media screen and (min-width $S_width_horizontal)
    min-width 370px
    width 50%
    retina(@block {
      width: 70%
      min-width: 450px
      margin-bottom: $abstand_xs
    })
  @media screen and (min-width $M_width)
    min-width 400px
    margin-bottom $abstand_l
    retina(@block {
      width: 70%
      min-width: 470px
      margin-bottom: $abstand_xs
    })
  @media screen and (min-width: $L_width) and (-webkit-min-device-pixel-ratio 1)
    color $neuland_rotorange
  @media screen and (min-width: $L_width)
    retina(@block { margin-bottom: $abstand_l })
  @media screen and (min-width $XL_width)
    color $neuland_rotorange
    retina(@block {
      width: 50%
      max-width: 560px
      color: $neuland_rotorange
    })

.gruen
  .pageIntro    
    @media screen and (min-width: $L_width) and (-webkit-min-device-pixel-ratio 1)
      color $neuland_gruen
    @media screen and (min-width $XL_width)
      color $neuland_gruen
      retina(@block {
        width: 50%
        max-width: 560px
        color: $neuland_gruen
      })
