.section
  box-sizing border-box
  position relative
  font-family $base-font-family
  clear both
  margin $abstand_m auto $abstand_xl auto
  p:last-child
   margin-bottom 0
  @media screen and (min-width: $L_width)
    margin $abstand_xxl auto
  &--homeText
    margin-top 0
  &--divider
    width 100%
    max-width 100%
    min-height 300px
  &--jobBoxes
    padding 0 $abstand_xs
  &--imageAndText
    margin-bottom $abstand_xxs
  &__title
    @extends .formattedText h2

  &--rotorange
    background-color $neuland_rotorange
    color $neuland_weiss
    .button--submit, .button--primary
      background-color $neuland_offwhite
      color $neuland_schwarz
      &:hover
        background-color $neuland_schwarz
        color $neuland_weiss
  &--offwhite
    background-color $neuland_offwhite