.pageTitle
  sideSpacing()
  fontSizePageTitle()
  font-family $base-font-family
  font-weight normal
  text-align left
  padding-top $abstand_xs
  padding-bottom $abstand_xs
  color $neuland_schwarz
  @media screen and (max-width $S_width_horizontal)
    hyphens auto

.pageTitle--blend
  color white

.pageTitle--under
  color $neuland_schwarz
  margin-top $abstand_s
  padding-left unset