sideSpacing()
  padding-left $abstand_m
  padding-right $abstand_m
  @media (min-width $M_width)
    padding-left $abstand_xxl
    padding-right $abstand_xxl
  @media (min-width $XL_width)
    > *
      max-width 1280px
      margin-left auto
      margin-right auto


//Schriftgrößen

//mobil P2, desktop P3
fontSizeSmall()
  /* sollte eigentlich mobil 14 und desktop 18 werden (mit Betrachtung retina!!), dass ist aber definitiv zu klein für die gewünschten Texte!
  Daher werden hier mobil vorerst die gleichen Größen wie medium (= p2 in figma) genommen*/
  font-weight normal
  line-height 1.4em
  font-size 0.88rem // Mobil: siehe medium
  retina({font-size: 0.82rem});
  @media screen and (min-width: $M_width)
    font-size 0.74rem  // desktop:
    retina({font-size: 0.82rem}); //retina hier noch eher als mobile anzusehen

  @media screen and (min-width: $L_width)
    retina({font-size: 0.7rem}); //retina 21px - erst hier nicht mehr als  mobile anzusehen
    //TODO: die retina-Funktion klappt hier gerade irgendwie nicht. wenn diese direkt an der entsprechenden Stelle eingetragen wird, funktioniert sie.

//mobil P2, desktop P2
fontSizeMedium()
  /* Hinweis: Umsetzung bewusst gegen Figmaentwürfe: Hier wäre mobil sogar min. 16px non-retina optimal, alles andere ist fast zu klein.. */
  font-size 0.88rem // Mobil: 15.84px non-retina
  retina({font-size: 0.82rem}); // Mobil: 18.04px retina
  font-weight normal
  line-height 1.4em
  @media screen and (min-width: $M_width)
    font-size 0.8rem // Desktop: 18.4px non-retina
    retina({font-size: 0.82rem}); // hier noch retina Mobil: 18.04px retina
  @media screen and (min-width: $L_width)
    retina({font-size: 0.8rem}) // Desktop: 24px retina

//P1
fontSizeStandard()
  font-size 1rem // 30px retina, 23px non-retina
  font-weight normal
  line-height 1.4em
  @media screen and (min-width: $L_width)
    retina({font-size: 1rem}) // Desktop: 24px retina

fontSizeL()
  font-size 1.125rem //neu - noch nicht von julia definiert
  font-weight normal
  line-height 1.4em

fontSizePageTitle() // vielleicht später fontSizeXL() und XL wird zu XXL da keine verwendung für extrem große Zahlen
 font-size 2.167rem
 font-weight normal
 line-height 1.3em

fontSizeXL()
  font-size 2.619rem // 55 (retina)
  font-weight normal
  line-height 2.955rem
  @media screen and (min-width $M_width)
    font-size 2.833rem //85 (retina)
    line-height 3rem

fontSizeXXL()
  font-size 4.762rem // 100 (retina)
  font-weight normal
  line-height 5rem
  @media screen and (min-width $M_width)
    font-size 4rem // 120 (retina)
    line-height auto


// Basis-Schriftgrößen für das html-Element als Basis für rem Werte
baseFontSize()
  font-size 18px
  retina({font-size: 22px});

  @media (min-width: $M_width)
    font-size 23px
    retina({font-size: 22px}); // retina ein breakpoint später switchen

  @media (min-width: $L_width)
    retina({font-size: 30px});


/* for retina media queries */
retina($content)
  @media screen and (-webkit-min-device-pixel-ratio 2) and (min-resolution 192dpi) and (min-resolution 2dppx)
    { $content }
