.wideText
  color $neuland_red
  font-family $base-font-family
  font-style normal
  font-size 30px
  padding 50px
  letter-spacing -1px
  @media screen and (max-width $M_width)
    font-size 20px
  blockquote
    max-width 1280px
    margin 0 auto
  p 
    margin-bottom 0
  &--bright
    color #8D8D8D
    background-color #F3F3F3