.tagCloud
  margin-bottom 1.5rem
  ul
    padding 10px 0 0px 10px
  &__title
    @extends .formattedText h3
    margin-top 10px
  &__tags
    list-style none
    display flex
    justify-content flex-start
    flex-wrap wrap
    background #f0f2f5
  &__tag
    display flex
    margin 0 10px 10px 0
    &:before
      content none !important
    a
      font-size 20px
      box-sizing border-box
      display inline-block
      border 1px solid rgba(66,66,66,0.3)
      border-radius 5px
      background-color #fff
      color rgba(66,66,66,0.6)
      padding 2px 7px
      -webkit-transition max-height 0.6s ease-out
      -moz-transition max-height 0.6s ease-out
      -o-transition max-height 0.6s ease-out
      transition max-height 0.6s ease-out
      text-decoration none
      &:hover
        background-color $neuland_red
        color #fff
      @media screen and (max-width: $L_width)
        font-size 16px
    &--active
      a
        background-color $neuland_red
        color #fff