.gallery
  &__header
    height 38.2vh
    position relative
    display flex
    align-items center
    justify-content center
  &__headline
    width 100%
  &__images
    display flex
    flex-wrap wrap
    //overflow hidden
    //overflow-x auto


  &__item
    height auto
    align-content center
    background-color $neuland_schwarz
    margin $abstand_xs 0
    margin-right $abstand_s
    &:last-of-type
      margin-right 0
    @media screen and (max-width $M_width - 1)
      width "calc(50% - %s)" % $abstand_xs
      max-height 10rem
      &:nth-of-type(2)
        margin-right 0
    @media screen and (min-width $M_width) and (max-width $XL_width - 1)
      width "calc(33.33% - (%s * 2/3))" % $abstand_s
      max-height 14rem
      &:nth-of-type(3)
        margin-right 0
    @media screen and (min-width $XL_width)
      width "calc(25% - (%s * 3/4))" % $abstand_s
      max-height 14rem
      &:nth-of-type(4)
        margin-right 0
    &:hover
      transform scale(1.4)
      z-index 55

  /*&__image
    justify-self middle
    width auto
    height 100%
*/
.galleryItem
  //overflow hidden
  position relative
  &__image
    width 100%
    height 100%
    object-fit cover
    justify-self center
  &__image[data-lazy-loaded=false]
    -webkit-filter blur(16px)
    filter blur(16px)
    transform scale(1.2)
  &__control
    width 100%
    height 100%
    background-color rgba(0, 0, 0, 0.1)
    position absolute
    top 0
    opacity 0
    transition opacity .3s

  &__imageLink
    &::before
      content ''
      height 100%
      width 100%
      background-color black
      display block
      position absolute
      top 0   
      opacity 0
      pointer-events none
      transition opacity .3s
  &:hover
    .galleryItem__control
      opacity 1
    .galleryItem__imageLink
      &::before
        opacity .1
