.imageSource
  position absolute
  bottom 0
  right 0
  background rgba(255, 255, 255, 0.35)
  padding 0 20px
  font-size 12px
  opacity .6
  z-index 2
  cursor inherit
  color black
  &:hover
    color black
    opacity 1
    background rgba(255, 255, 255, 0.6)