.bugCategory
  margin-top 4em
  &__bugs
    display flex
    justify-content space-around
    flex-flow row wrap
    align-content center
  &__titleText
    color $neuland_red
    font-size 28px
    position relative
    top -10px
    margin-left 10px
  &__titleImage
    display inline-block
    height 100%
    width auto
  &__title
    width 250px
    height 40px
  &__bug
    margin 3em 6em 2em 0
  &__bugDescription
    width 250px
    margin-top 10px
    color grey
    &:hover
      color $neuland_red
  &__bugTitle
    margin-bottom 10px

.bugIcon
  width 40px
  height 40px
  fill rgba(0, 0, 0, 0.16) //rgba(0, 0, 0, 0.3)
  &:hover
    fill $neuland_green

.bugIconContainer
  position fixed
  display block
  height 55px
  width 45px
  z-index 99
  right 10px // 77px
  bottom 0
  @media screen and (max-width: $L_width - 1)
    display none
.bugIconContainer--eventplan
  .bugIcon
    fill rgba(136, 136, 136, 0.5)
    &:hover
      fill $neuland_red
  // @media screen and (min-width: $L_width)
  //   top 7px
  //   right 7px