.jobOverview
  &__jobs
    width 100%
    @media screen and (max-width: $L_width)
      width calc(100% + 30px)
      margin-left -15px
  &__job
    margin-bottom 20px
    display block
    position relative
    // TODO: Bessere Lösung finden, um das Bild Weiß/Schwarz zu kriegen
    // -moz-filter  url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")
    // -o-filter  url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")
    // -webkit-filter  grayscale(100%);
    // filter gray
    // filter url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale")
    display block
    width 100%
    &:hover
      -moz-filter none
      -o-filter none
      -webkit-filter none
      filter none
      filter none
  &__link
    position relative
    display block
  &__jobImage
    height 25vh
    min-height 200px
    width 100%
    &:after
      display block
      content ""
      width 100%
      height 100%
      position absolute
      top 0
      opacity 0.2
      z-index 1
      background rgb(167,175,57) /* Old browsers */
      background -moz-linear-gradient(top,  rgba(167,175,57,1) 0%, rgba(158,24,47,1) 100%) /* FF3.6-15 */
      background -webkit-linear-gradient(top,  rgba(167,175,57,1) 0%,rgba(158,24,47,1) 100%) /* Chrome10-25,Safari5.1-6 */
      background linear-gradient(to bottom,  rgba(167,175,57,1) 0%,rgba(158,24,47,1) 100%) /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  &__jobTitleWrapper
    position absolute
    bottom 0
    z-index 2  
    width 100%
    -webkit-font-smoothing: antialiased;
    font-family $base-font-family
    padding 20px
  &__jobTitle
    flex-wrap wrap
    display flex
    position relative
    color #FFF
    text-decoration none
    width $L_width
    margin 0 auto
    font-size 1.828rem
    line-height 3rem
    @media screen and (max-width: $L_width)
      width calc(100vw - 40px)
      font-size 1.414rem
  &__jobTitleBackground
    padding 10px 20px
    background-color rgba(0,0,0,0.75)
    border-radius 5px
    display inline
    line-height 1.3em
    
.jobOverview--homepage
  &__column
    @media screen and (min-width: $M_width)
      width calc(50% - 10px)
  .jobOverview
    &__jobTitle
      width auto
      font-size 1.414rem
    &__jobs
      overflow auto
      @media screen and (min-width: $M_width)
        display flex
        flex-wrap wrap
        justify-content space-between
      @media screen and (max-width: $L_width) and (min-width: $M_width)
        width 100%
        margin-left 0px
    &__jobTitle
      flex-wrap wrap
      display flex
    &__jobTitleWrapper
      padding-left 20px
      
.jobOverview--fullWidth
  .jobOverview
    &__job
      @media screen and (min-width: $M_width)
        display block
        width 100%   