@require 'imageSource'
.blog
  margin 0 auto
  font-family $base-font-family
  font-weight normal
  -webkit-transition all 250ms
  transition all 250ms
  &__newsHeadline
    padding 25px 0 20px
    color $neuland_green
    @media screen and (max-width: $M_width)
      padding: 0 20px
  &__lead
    color $neuland_rotorange
    padding-top 2em
    margin-bottom 2em
  &__date--entry, &__author--entry
    fontSizeSmall()
  &__article
    &.formattedText
      .blog__lead p
        fontSizeStandard()
      h2
        fontSizeL()
        color $neuland_rotorange
        margin-bottom $abstand_xs
        &.section__title
          fontSizePageTitle()
          color $neuland_schwarz
      h3
        fontSizeStandard()
      blockquote
        margin-top $abstand_xl
  &__bodyText
    margin-bottom 40px  
    @media screen and (min-width: $L_width)
      margin-bottom 100px
  &__info
    margin-top 10px
  &__authorName
    color $base-font-color
  &__list
    list-style none
    @media screen and (min-width: $M_width)
      display flex
      flex-wrap wrap
      justify-content space-between
    &--highlighted
      margin-top 10px
      @media screen and (max-width: $M_width)
        flex-wrap wrap
        flex-direction row
  &__entry
    position relative
    padding 0 0 32px 0
    -webkit-transition all 250ms
    transition all 250ms
    overflow auto
    align-content space-between
    @media screen and (min-width: $M_width)
      width calc( 50% - 10px )
    &:before
      content none !important
    @media screen and (max-width: $M_width)
      flex-wrap wrap
    &--highlighted
      display flex
      position relative
      justify-content space-between
      flex-grow 0
      flex-basis 0
      flex-wrap wrap
      -webkit-transition all 250ms
      transition all 250ms
      min-width 33.33333%
      @media screen and (max-width: $M_width)
        flex-wrap nowrap
        justify-content flex-start
        width 100%
        flex-basis 100%
  &__dynamicLoading
    display flex
    justify-content center
  &__loadMoreContent
    box-sizing border-box
    border 1px solid rgba(66,66,66,0.3)
    color rgba(66,66,66,0.6)
    padding 5px 10px
    border-radius 5px
    cursor pointer
    font-size 16px
    font-family $base-font-family
    outline none
    &:hover
      background #f5f5f5
    @media screen and (max-width: $L_width)
      font-size 16px
  &__pagination
    text-align center
    position relative
    margin-top 10px
  &__paginationText
    box-sizing border-box
    display inline-block
    border 1px solid rgba(66,66,66,0.3)
    border-right 0
    color rgba(66,66,66,0.6)
    padding 10px
    -webkit-transition max-height 0.6s ease-out
    -moz-transition max-height 0.6s ease-out
    -o-transition max-height 0.6s ease-out
    transition max-height 0.6s ease-out
    text-decoration none
    margin-left -5px
    &:first-child
      border-top-left-radius 5px
      border-bottom-left-radius 5px
    &:last-child
      border-top-right-radius 5px
      border-bottom-right-radius 5px
      border-right 1px solid rgba(66,66,66,0.3)
    &:hover
      background-color $neuland_red
      color #fff
    &--active
      background-color $neuland_red
      color #fff
    
  &__sharearea
    padding-top 20px
  &__sharebutton
    a
      box-sizing border-box
      display inline-block
      border 1px solid rgba(66,66,66,0.3)
      border-radius 5px
      color rgba(66,66,66,0.6)
      padding 10px
      -webkit-transition max-height 0.6s ease-out
      -moz-transition max-height 0.6s ease-out
      -o-transition max-height 0.6s ease-out
      transition max-height 0.6s ease-out
      text-decoration none
      &:hover
        background-color $neuland_red
        color #fff
  &__socialButtons
    list-style none
    padding 0
    margin 0
    &--hidden
      display none
    &--show
      display block
  &__socialButton
    display inline-block
    margin 0 !important
    padding 0 !important
    margin-right 10px !important
    &:before
      display none !important
  &__nextPrevButtons
    margin-top 15px
  &__nextPrevButton
    box-sizing border-box
    display inline-block
    border 1px solid rgba(66,66,66,0.3)
    border-radius 5px
    color rgba(66,66,66,0.6)
    padding 10px
    -webkit-transition max-height 0.6s ease-out
    -moz-transition max-height 0.6s ease-out
    -o-transition max-height 0.6s ease-out
    transition max-height 0.6s ease-out
    text-decoration none
    &:hover
      background-color $neuland_red
      color #fff
    &--next
      float right
      &:after
        content "→"
    &--prev
      float left
      &:before
        content "←"
      
  &__featuredEntry
    flex-basis 66%
  /*&__featuredEntry--noTags
    margin-bottom 32px*/

  &__tagCloud
    @media screen and (min-width: $M_width)
      flex-basis 33%
  &__titleByAuthor 
    margin-bottom 20px
  &__choosedTag
    text-align right
    margin-bottom 10px
  &__choosedCategory
    text-align right
    margin-bottom 10px
    
.formattedText
  padding-left 0

.blog--homepage
  .blog
    &__list
      margin-right -16px
      margin-bottom 20px
    &__entry
      display inline-block
      vertical-align top
      padding-right 16px
      @media all and (min-width: 500px)  
        width 33.3333333%
    &__image
      width 100%
      float none
    &__content
      width 100%
      min-height 162px
      padding-right 15px
      padding-left 0
      margin-bottom 0
    &__title
      margin-top 15px