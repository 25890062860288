.formattedText
  font-family $base-font-family
  text-align left
  color $base-font-color

  &--coloredBackground
    padding 1rem 1.5rem
    margin-bottom 2rem
    background-color $neuland_offwhite
    p:last-of-type
      margin-bottom 0
    &--rotorange
      background-color $neuland_rotorange
    &--offwhite
      background-color $neuland_offwhite

  &--textColor
    &--rotorange
      color $neuland_rotorange
    &--schwarz
      color $neuland_schwarz
    &--weiß
      color $neuland_weiss

  pre, code
    font-family TheSansMono

  pre
    padding $abstand_s
    background-color #F0F2F5
    margin-bottom 1.5em
    border-radius 5px
    overflow auto
    
  p
    fontSizeMedium()
    margin-bottom 1em

  figure
    margin-bottom 1em

  ul,
  ol
    margin-bottom $abstand_xs
    counter-reset li /* Initiate a counter */
    padding-left 1.75em

  @media screen and (max-width: $M_width)
    ul
      padding-left 1em
    ol
      padding-left 17px
    ol ul
      padding-left 20px

  ul > li
    position relative
    &:before
      content " "
      background-color $neuland_schwarz
      height 5px
      width 5px
      border-radius 5px
      retina(@block{
        height 6px
        width 6px
        border-radius 6px
      })
      display block
      position absolute
      left -1em
      top .5em

  ul > li > p
    margin-bottom 0

  ol > li
    margin-left $abstand_xs
    position relative
    text-align left
    list-style none
    // margin 0 0 16px 2em /* Give each list item a left margin to make room for the numbers */
    // padding 4px 8px  /* Add some spacing around the content */
    
    &:before
      content:counter(li) "." /* Use the counter as content */
      counter-increment:li /* Increment the counter by 1 */
      position absolute
      color $neuland_rotorange
      left -1.2em
    
  ul ul,
  ol ul,
  ul ol,
  ol ol
    margin-top 0
    margin-bottom 0

  h1,
  h2,
  h3,
  h4,
  h5,
  h6
    font-family $base-font-family
    margin-bottom 0
    line-height 1.3em

  h1
    fontSizeXL()
    margin-top 3rem
    font-weight normal
    margin-bottom 0.9rem

  h2
    color $neuland_schwarz
    fontSizeXL()
    margin-bottom 1.4rem
    font-weight normal
      
    
  p + h2, blockquote + h2, figure + h2, a + h2, pre + h2, ul + h2, ol + h2
    margin-top $abstand_m

  figure
    text-align center
    
  h3
    fontSizeL()
    font-weight normal
    margin-bottom $abstand_xs
    color $neuland_rotorange
    text-transform normal

  p + h3, blockquote + h3, figure + h3, a + h3, pre + h3, ul + h3, ol + h3
    margin-top 1.5rem

  h4, h5
    fontSizeStandard()
    font-weight normal
    line-height 1.3
    margin-bottom $abstand_xs

  h6
    fontSizeMedium()

  table
    margin-top 1.5rem
    border-spacing 0
    border-collapse collapse

  table td,
  table th
    padding 0
    line-height 33px

  code
    vertical-align bottom

  .lead
    font-size 1.414rem

  .hug
    margin-top 0
    
  .rightFloat
    float right !important
    margin-left 20px
    padding-right 0
    @media screen and (min-width: 1280px)
      margin-right -150px
  .leftFloat
    float left !important
    padding-left 0
    padding-right 20px
    @media screen and (min-width: 1280px)
      margin-left -150px

  blockquote
    color $neuland_rotorange
    fontSizeL()
    text-align center
    max-width 1280px
    p 
      max-width none
      margin-left $abstand_xxxl
      margin-right $abstand_xxxl
      fontSizeL()
      @media screen and (max-width $L_width)
        margin-left $abstand_m
        margin-right $abstand_m      
      @media screen and (max-width $M_width)
        margin-left $abstand_s
        margin-right $abstand_s
     
.formattedText--box 
  h2
    @extends .formattedText h3
    text-transform none
  h3
    fontSizeL()
    font-weight normal
    letter-spacing 0
    color #444
    padding-bottom 0
    margin-bottom 0

.gruen
  .formattedText
    blockquote, h3
      color $neuland_gruen