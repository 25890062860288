.imageAndText
  display flex
  align-items center
  justify-content space-between
  @media screen and (max-width: 600px )
    flex-wrap wrap
  &__image
    width 33%
    flex-shrink 0
    @media screen and (max-width: 600px )
      min-width 100%
    &--left
      margin 0 20px 20px 0
      @media screen and (max-width: 600px )
        margin 0 0 15px 0
    &--right
      margin 0 0 20px 20px
      order 1
      @media screen and (max-width: 600px )
        margin 0 0 15px 0
        order 0