.centeredContentHeadline
  .constrainedContent
    margin 0
    padding 0
  &__headline, &__subHeadline
    text-align center
    display block
  .formattedText &__subHeadline
    margin-top $abstand_l
    fontSizeL()

.section--white, .section--offwhite
  .centeredContentHeadline__headline
    color $neuland_rotorange

.section--white .centeredContentHeadline__subHeadline
  color $neuland_rotorange

.gruen
  .centeredContentHeadline
    &__headline, &__subHeadline
      color $neuland_gruen
