.image
  display flex
  margin 0 auto
  &--fullwidth
    width 100%
    height auto
    object-fit cover
  &__wrapper
    position relative
    &--withStoerer
      padding-top $abstand_l
      padding-bottom $abstand_l
      margin-top 1.7rem
      @media screen and (min-width $M_width)
        margin-top 0
        padding-top $abstand_m
        padding-bottom $abstand_m
  &__stoerer
    position absolute
    top -2rem
    right 0
    text-align center
    align-content center
    padding 0.5rem
    aspect-ratio 1/1
    color $neuland_weiss
    background-color $neuland_rotorange
    border-radius 4rem
    fontSizeMedium()
    &:hover
      background-color $neuland_rotorange_hover
      color $neuland_weiss
    @media screen and (min-width $M_width)
      fontSizeStandard()
      top -2.5rem
      padding 0.7rem

.imageRoundCornersLeftAndGrayscale 
  border-top-left-radius: 41.65% 50%
  border-bottom-left-radius: 41.65% 50%
  aspect-ratio 1.0
  object-fit cover
  filter grayscale(100%)
  height: 100%;
  width: 100%;

.imageRoundCornersRightAndGrayscale 
  border-top-right-radius 41.65% 50%
  border-bottom-right-radius 41.65% 50%
  aspect-ratio 1.0
  object-fit cover
  filter grayscale(100%)
  height: 100%;
  width: 100%;
 
figure
  flex-wrap wrap
  sideSpacing()

  &[data-ratio="1/1"]
    .image--fullwidth
      height 100vw
  &[data-ratio="16/9"]
    .image--fullwidth
      height (100/16)*9vw
  &[data-ratio="10/8"]
    .image--fullwidth
      height (100/10)*8vw
  &[data-ratio="21/9"]
    .image--fullwidth
      height (100/21)*9vw
  &[data-ratio="7/5"]
    .image--fullwidth
      height (100/7)*5vw
  &[data-ratio="4/3"]
    .image--fullwidth
      height (100/4)*3vw
  &[data-ratio="5/3"]
    .image--fullwidth
      height (100/5)*3vw
  &[data-ratio="3/2"]
    .image--fullwidth
      height (100/3)*2vw
  &[data-ratio="3/1"]
    .image--fullwidth
      height (100/3)*1vw

  img
    margin 0 auto

  figcaption
    fontSizeSmall()
    padding-top $abstand_xxs


