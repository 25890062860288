.nav
  justify-content space-between
  z-index 55
  position fixed
  width 100%
  top 0
  left 0
  display inline-block
  background-color $neuland_weiss

  &__logo
    grid-area logo
    display block
    width 100%
    justify-content left
    .icon--neulandlogo
      fill #ff5a55
      display inline-flex
      width 120px
      retina({ width: 140px })
      @media screen and (min-width: $M_width)
        width 148px
      @media screen and (min-width: $L_width)
        retina({ width: 188px })

  &__menu
    grid-area menu
    display none
    margin-top 0
    @media screen and (min-width: $L_width)
      justify-content center
      display flex
    @media screen and (min-width: $L_width)
      retina({display: none})
    @media screen and (min-width: $XL_width)
      retina(@block {
        justify-content: center
        display: flex
      })
  &__ctas
    grid-area ctas
    display flex
    width 100%
    justify-content right
    padding-top 4px
    height fit-content
    align-self center
    @media screen and (min-width: $L_width)
      display flex
      margin-top 0.3rem

  &__container
    height 4rem
    padding-top $abstand_s
    padding-bottom $abstand_xs
    align-items center
    display flex
    justify-content space-between
    @media screen and (min-width: $M_width) and (-webkit-min-device-pixel-ratio 1)
      grid-template-columns 188px minmax(350px, auto)
      grid-template-areas "logo ctas"
    @media screen and (min-width: $L_width) and (-webkit-min-device-pixel-ratio 1)
      display grid
      grid-gap 0
      align-items baseline
      grid-template-columns 195px auto 170px
      retina({grid-template-columns: 188px minmax(20px, auto)})
      grid-template-areas "logo menu ctas"

    @media (min-width: $XL_width)
      retina(@block {
        grid-template-columns: 212px auto 188px;
        grid-template-areas: \
            "logo menu ctas"\
            ".    . .    .";
      })

  &__menuItem
    fontSizeStandard()
    padding-right $abstand_s
    cursor pointer
    text-decoration none
    a
      color #000     
      &:hover::before
        content ""
        display block
        height 3px 
      &:hover::after
        content ""
        display block
        height 3px
        background-color $neuland_rotorange
      &::before
        content ""
        height 3px
        display block        
      &::after
        content ""
        height 3px
        display block

  &__menuCta
    display none
    fontSizeSmall()
    border-radius 100px 100px 0 100px
    @media screen and (min-width: $L_width)
      display block

  .menu
    font-family $base-font-family

  &__burgermenu
    padding-top 0.5rem
    align-content center
    padding-left $abstand_xs
    @media screen and (min-width $L_width)
      padding-top 0

    &__toggle
      cursor pointer
    @media screen and (min-width $L_width)
      display flex
      align-items center
      height 100%

    &__icon
      height 100%
      min-width 1.15rem
      @media screen and (min-width $L_width)
        retina({min-width: 1rem})
      &--open
        display block
        .navSubmenuActive &
          display none
      &--close
        display none
        .navSubmenuActive &
          display block

.navSubmenuActive
  .nav
    background-color $neuland_rotorange
    box-shadow 0 10px 10px -10px rgba(0, 0, 0, 0.23)
    &__logo .icon--neulandlogo
      fill $neuland_weiss
    &__menuItem, .cta__button
      display none
  &.gruen .nav
    background-color $neuland_gruen
    &__logo .icon--neulandlogo
      fill $neuland_weiss

.gruen
  .nav
    &__logo .icon--neulandlogo
      fill $neuland_gruen
    &__ctas a:hover
      color $neuland_weiss
    &__menuItem a:hover::after
      background-color $neuland_gruen


