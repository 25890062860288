.linksToNeighbors
  display flex
  margin-bottom 45px
  justify-content space-between
.linksToNeighbors--newestArticle
  justify-content flex-end
.linkToNeighbor
  display flex
  @media screen and (min-width: 1113px)
    position fixed
    top 50%
    transform translateY(-50%)
    z-index 3
  &__arrow
    @media screen and (max-width: 1113px)
      margin-top 60px
    @media screen and (max-width: 520px)
      margin-top 25px
    @media screen and (min-width: 1113px)
      box-sizing border-box
      border 1px solid rgba(66,66,66,0.3)
      border-radius 5px
      background white
    &--right
      order 1
      margin-left 4px
    &--left
      margin-right 4px
  &--left
    left 15px
    align-items center
    @media screen and (max-width: 1113px)
      align-items flex-start
  &--right
    right 15px
    align-items center
    @media screen and (max-width: 1113px)
      align-items flex-start
  &__title
    @media screen and (max-width: 520px)
      font-size 12px
  &__preview
    width 210px
    @media screen and (min-width: 1113px)
      display none
      transition all .3s
      max-width 38vw
    @media screen and (max-width: 520px)
      width 110px
.linkToNeighbor:hover
  @media screen and (min-width: 1113px)
    .linkToNeighbor__preview
      display block 
      box-shadow 0 0 0 1px rgba(0, 0, 0, .2)
      background-color white
    .linkToNeighbor__arrow
      background #eaeaea
      align-self center
    .linkToNeighbor__title
      color $base-font-color
      font-size 14px
      font-weight bold
      padding 10px