.icon
  fill #8e8e8e
  &:hover
    fill $neuland_green
.header--dark
  .icon:hover
    fill $neuland_red
.iconContainer
  cursor pointer
  position fixed
  display block
  z-index 60
  top -2px
  right 20px
  @media screen and (min-width: $XL_width)
    top 15px
  
.searchHeader
  display none
  z-index 50
  position fixed
  right 15px
  padding 7px
  background-color rgba(255,255,255,0.95)
  top 82px
  @media screen and (max-width: $XL_width - 1)
    top 55px
    right 0
    z-index 99
  &__field
    width 370px
    height 40px
    padding 5px 10px
    font-size 16px
    font-family $base-font-family
    color $base-font-color //!important
    outline 1px solid $neuland_green
    border 1px solid rgba(66,66,66,0.3)
    @media screen and (max-width: $XL_width - 1)
      width 300px
      height 36px
    &::-webkit-search-cancel-button
      margin-right 30px
  &--visible
    display block
  .search__button
    top 10px

.search
  &__form
    position relative
  &__inputfield
    width 100%
    height 40px
    padding 5px 10px
    box-sizing border-box
    font-size 16px
    font-family $base-font-family
    color $base-font-color !important
    outline 1px solid $neuland_green
    border 1px solid rgba(66,66,66,0.3)
    &::-webkit-search-cancel-button
      margin-right 35px
  &__button
    top 3px
    position absolute
    right 0
    border none
    outline none
    svg
      position absolute
      right 13px
      top 0
      height 33px
  &__controls
    margin-bottom 20px
    padding 10px
  &__countResult
    margin-top 14px
    color $base-font-color
    font-size 24px
    font-weight bold
  &__result
    padding 10px
    a
      color #444
    &:hover
      background #f5f5f5
      a
        color $neuland_red
      .result__parent
        background $neuland_red
  &__loadContent
    display flex
    justify-content center
    padding-top 32px
  &__loadButton
    box-sizing border-box
    border 1px solid rgba(66,66,66,0.3)
    color rgba(66,66,66,0.6)
    padding 5px 10px
    border-radius 5px
    cursor pointer
    font-size 16px
    font-family $base-font-family
    outline none
    &:hover
      background #f5f5f5
    @media screen and (max-width: $M_width)
      font-size 14px
    
.result
  display flex
  &__image
    width 100px
    min-width 100px
    margin-right 10px
    line-height 0  // why?
    @media screen and (max-width: $S_width_horizontal)
      display none
  &__abstract
    font-size 14px
    margin-top 3px
  &__title
    margin-bottom 5px
    font-weight 500
    font-size 1.2em
    display inline
  &__parent
    display inline
    background #7d7d77
    color #fff
    padding 0 5px
    border-radius 3px
    margin-right 5px
    margin-left 3px

.header--dark
  .searchHeader
    background-color rgba(29, 29, 27, 0.9)
    &__field
      outline 1px solid $neuland_red
      color #fff
      background-color #272727
