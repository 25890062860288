.floatImage
  -webkit-shape-margin 20px 
  float left 
  min-width 180px
  max-width 50%
  margin-bottom 1em
  &__caption
    font-size 12px
    opacity .3
    line-height 2em
    text-align left

  &--right
    float right !important
    margin-left 20px
    padding-right 0
    @media screen and (min-width: 1280px)
      margin-right -150px
  &--left
    float left !important
    padding-left 0
    margin-right 20px
    @media screen and (min-width: 1280px)
      margin-left -150px