.project
  display flex
  justify-content flex-start
  &__text
    width 620px
  &__sidebar
    width 300px
    padding-left 20px
    margin-left 20px
    border-left 1px solid rgba(66,66,66,0.3)
  &__tag
    background-color: $neuland_red
    color #FFF
    padding 3px 6px
    display inline-block
    margin-bottom 5px
    border-radius 5px
  &__news
    text-align left
    text-decoration none
  &__newsLink
    margin-bottom .4em
    display block
    
.newsArticle
  &:before
    content "•"
    color $neuland_red
    margin-right 5px
    font-size 20px