@import 'variables'
@import 'mixins'
@import 'typography/*'
@import 'blocks/*'
@import 'photoswipe/photoswipeUi.css'

html
  baseFontSize()
  scroll-padding-top 5.7rem
  scroll-behavior smooth

.main
  padding-top $abstand_xxl
  padding-bottom 100px
  @media screen and (min-width: $M_width)
    padding-top 4.1rem
    retina({padding-top: $abstand_xxl})
  @media screen and (min-width: $L_width)
    retina({padding-top: 4.1rem})

  &--dark
    background-color #1d1d1b

body
  font-family $base-font-family
  -webkit-font-smoothing antialiased
  background white
  font-size 1rem
  fontSizeMedium()

*
  margin 0
  padding 0
  box-sizing border-box

strong
  font-family $base-font-family

img, picture, source
  max-width 100%
  display block
  align-self flex-start

a
  color $neuland_schwarz
  text-decoration none
  -webkit-tap-highlight-color rgba(0, 0, 0, 0)
  &:hover
    color $neuland_rotorange
  &:focus
    -webkit-tap-highlight-color rgba(0, 0, 0, 0)
  &:visited
    -webkit-tap-highlight-color rgba(0, 0, 0, 0)
  &:active
    -webkit-tap-highlight-color rgba(0, 0, 0, 0)
.gruen a:hover
  color $neuland_gruen

ul
  list-style none
  margin 0
  padding 0
  -webkit-transition max-height 0.6s ease-out
  -moz-transition max-height 0.6s ease-out
  -o-transition max-height 0.6s ease-out
  transition max-height 0.6s ease-out

iframe
  border none

.hero
  background transparent url(neuland.jpg) 0 0 no-repeat
  background-size cover
  width auto
  height 50vh
  background-position-y 50%
  position relative

.lHide
  @media screen and (max-width: $L_width)
    display none

.mHide
  @media screen and (max-width: $M_width)
    display none

.sHide
  @media screen and (max-width: $S_width)
    display none

.noLineBreakText
  white-space nowrap

.neulandbot
  position fixed
  display block
  right 8px
  bottom 8px
  width 40px
  img
    width 100%

.font-medium
  fontSizeMedium()

.font-small
  fontSizeSmall()

small
  display inline-block  //Fix für zu hohe line-height bei small-Tag
  fontSizeSmall()

textarea, input, button, select
  font-family $base-font-family
  -webkit-tap-highlight-color rgba(0, 0, 0, 0)

strong
  color $neuland_rotorange
  font-weight normal
.gruen strong
  color $neuland_gruen