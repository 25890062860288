.authorBox
  clear both
  &__content
    display flex
    background #F0F2F5
    font-size 14px
    padding 10px
    margin-bottom 40px
    @media screen and (max-width: 500px)
      flex-wrap wrap
    @media screen and (min-width: $M_width)
      padding 20px
      padding-right 0
  &__author 
    color black
    flex-basis 100%
    align-items center
    align-content flex-start
    @media screen and (min-width: 500px)
      display flex
      padding-right 10px
    @media screen and (min-width: $M_width)
      padding-right 20px
    @media screen and (max-width: 500px)
      margin-bottom 20px
  &__description
    flex-grow 1
    line-height 1.5em
  &__portrait
    margin-right 20px
    @media screen and (max-width: 500px)
      margin-bottom 10px
      margin-right  0px
  &__profile
    align-self flex-start
    @media screen and (min-width: 500px)
      width 300px
      flex-shrink 0 
  &__socialMedia
    display flex
    justify-content space-between
    align-items flex-start
    width 100%
  &__socialLink
    margin-right 4px
  &__socialLinks
    align-self center
  &__infos
    flex-direction column
    display flex
  &__websiteLink
    margin-bottom 10px
    a
      color rgba(66,66,66,0.6)
  &__allArticlesLink
    margin-top 10px
    a
      color rgba(66,66,66,0.6)
  .authorBox__title
    h3
      margin-top 0px
      
.authorBox--multiple
  .authorBox
    &__portrait
      margin-right  0px
      margin-bottom 10px
    &__description
      width 100%
    &__profile
      @media screen and (min-width: 500px)
        width auto
        flex-shrink 1
    &__author
      align-items flex-start
  .authorBox__author
    @media screen and (min-width: 500px)
      flex-wrap wrap
      width calc(50% - 10px)
      flex-basis unset
      margin-bottom 30px
      padding-right 0px
  .authorBox__content
    @media screen and (min-width: 500px)
      justify-content space-between
      flex-wrap wrap
    @media screen and (min-width: $M_width)
      padding 20px

.authorBox--3
  .authorBox__author
    @media screen and (min-width: 500px) and (max-width: $M_width)
      width calc(50% - 10px)
      margin-bottom 30px
      flex-basis unset
      padding-right 0px
  @media screen and (min-width: $M_width)
    .authorBox__content
      justify-content space-between
    .authorBox__author
      width calc(33% - 10px)