.pagination
  font-size 16px
  display flex
  justify-content center
  &__pageNavi
    margin 0 3px
  &__number
    box-sizing border-box
    border 1px solid rgba(66,66,66,0.3)
    border-radius 5px
    color rgba(66,66,66,0.6)
    font-family $default-font-family
    padding 5px 10px
    &--active
      color black
      font-family $default-font-family
    &--link
      &:hover
        background-color $neuland_red
        color #fff
  &__prevPage
    margin-right 4px
  &__nextPage
    margin-left 4px