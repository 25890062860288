.constrainedContent
  sideSpacing()
  display block
  vertical-align top 
  margin 0 auto
  text-align left
  position relative
  clear both
  
  &__headline
    color $neuland_red
    margin 1em 0 0.5em
    text-transform uppercase
    font-size 1.5rem
    
  &--twothird
    width 65.66666%
    display inline-block
    padding-top 1.5rem
    padding-right 1.875rem
    @media screen and (max-width: $M_width)
      width 100%
      padding-right 0
      
  &--onethird
    width 33.33333%
    display inline-block
    vertical-align top
    padding-top $abstand_m
    @media screen and (max-width: $M_width)
      width 100%

  &--offwhite,
  &--rotorange,
  &--gruen
    padding-top $abstand_l
    padding-bottom $abstand_l
    margin-bottom $abstand_m
    @media (min-width $L_width)
      padding-top $abstand_xl
      padding-bottom $abstand_xl
      margin-bottom $abstand_xxl

    .formattedText h1
      margin-top 0

  &--offwhite
    background-color $neuland_offwhite
    color $neuland_schwarz
    .formattedText, .formattedText h2, .formattedText a:not(.button)
      color $neuland_schwarz
    .button--secondary
      background-color $neuland_rotorange
      color $neuland_weiss
      &:hover
        background-color $neuland_rotorange_hover
        color $neuland_weiss

  &--rotorange
    background-color $neuland_rotorange
    color $neuland_weiss
    .formattedText, .formattedText h2, .formattedText a:not(.button)
      color $neuland_weiss

  &--gruen
    background-color $neuland_gruen
    color $neuland_weiss
    .formattedText, .formattedText h2, .formattedText a:not(.button)
      color $neuland_weiss

  &--image, &--image.constrainedContent--offwhite, &--image.constrainedContent--rotorange, &--image.constrainedContent--gruen, &--media
    padding-top 0
    padding-bottom 0