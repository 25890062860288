body.navSubmenuActive
  @media screen and (max-width $L_width - 1)
    overflow hidden

.burgermenu
  display none
  background-color $neuland_rotorange
  height calc(100vh - 4rem)
  overflow-y auto
  overflow-x hidden
  &::-webkit-scrollbar
    width 0.5rem
    background $neuland_rotorange
  &::-webkit-scrollbar-thumb
    background-color #fff
    border-radius 10px
  @media screen and (min-width $L_width)
    max-height max-content
  &--active
    display block
  &__items
    background-color $neuland_rotorange
    width 100%
    padding-top $abstand_xxs
    padding-bottom $abstand_m
    @media screen and (max-width $L_width - 1)
      height 100%
    @media screen and (min-width $L_width)
      max-height max-content

  &__item
    fontSizeL()
    padding $abstand_xxs 0
    background-color $neuland_rotorange
    &--isActive
      .burgermenu__kategorie
        display block
        padding-top $abstand_xxs
      >.burgermenu__link
        font-weight bold
        position relative
        &:before
          content ""
          position absolute
          border-bottom 1px solid $neuland_weiss
          width 100%
          height 0
          bottom -5px
          left 0

  &__link, &__kategorie__toggle
    color $neuland_weiss
    cursor pointer
    &:hover, &:hover
      color $neuland_weiss
      opacity 0.8
      //font-weight bold

  &__kategorie
    display none
    &--active, &:has(.burgermenu__item--isActive)
      display block
      padding-top $abstand_xxs
    .burgermenu__item
      padding-left $abstand_s

.gruen
  .burgermenu
    background-color $neuland_gruen
    &::-webkit-scrollbar
      background $neuland_gruen
    &__link:hover, span:hover
      color $neuland_weiss
    &__items, &__item
      background-color $neuland_gruen