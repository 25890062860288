.section--contentSlider
  margin-bottom $abstand_xxl

  .slider
    position relative

  .swiper-wrapper
    column-gap 20px

  .swiper-slide.imageSlider__item
    height 2.25rem
    align-self center
    width auto
    img
      width auto
      height 100%
      padding-right $abstand_l
      vertical-align bottom
      object-fit scale-down
      max-width 9.75rem

  .contentSlider__item.swiper-slide
    height auto
    align-items flex-start
    justify-content space-between
    margin-right 0 !important
    width 100%
    @media screen and (min-width $S_width_horizontal)
      width calc(50% - 10px)
      retina({width: 100%})
      padding-right $abstand_xs
    @media screen and (min-width 580px)
      retina({width: calc(50% - 10px)})
    @media screen and (min-width $L_width)
      width calc(33.33% - 15px)
      retina({width: calc(50% - 10px)})
    @media screen and (min-width $XL_width)
      width calc(25% - 15px)
      retina({width: calc(50% - 10px)})
    /* Ausnahmen für gute Lesbarkeit*/
    @media screen and (min-width 1300px)
      retina({width: calc(25% - 15px)})

.imageSlider
  background-color $neuland_offwhite
  padding $abstand_xl 0
  .swiper-wrapper
    align-items center
  &__image
    justify-self center

.section--contentSlider, .section--imageSlider
  .slider__navigation
    display flex
    justify-content center
    .icon
      z-index 3
      margin-top $abstand_m
      margin-left $abstand_xxs
      margin-right $abstand_xxs
      fill $neuland_rotorange
      width 2.3rem
      cursor pointer
      @media screen and (min-width $M_width)
        width 1.8rem
        retina({width: 2.5rem})
      @media screen and (min-width $L_width)
        retina({width: 1.75rem})

    &__disableButton
      opacity 0.2
      .icon
        fill $neuland_schwarz
        cursor default

  .slider__prevSlide
    left 0
  .slider__nextSlide
    right 0