.kacheln
  display flex
  flex-wrap wrap
  height 100%
  gap $abstand_xs

  &__item
    hyphens auto
    flex 0 0 100%
    display flex
    min-width 8rem
    padding $abstand_s
    flex-direction column
    justify-content space-between

    @media (min-width: $S_width_horizontal)
      min-width 8rem
      min-height 8rem
      flex-basis "calc(50% - (%s * 1/2))" % $abstand_xs
      height 10rem
      &__content
        display flex
      &__readmore
        justify-self flex-end
    @media (min-width: $L_width)
      flex-basis "calc(33.33% - (%s * 2/3))" % $abstand_xs
    @media (min-width: $XL_width)
      flex-basis "calc(25% - (%s * 3/4))" % $abstand_xs

    &--bg-offwhite
      background-color $neuland_offwhite
      *
        color $neuland_schwarz
    &--bg-rotorange
      background-color $neuland_rotorange
      &:hover
        background-color $neuland_rotorange_hover
      *
        color $neuland_offwhite
    &--bg-gruen
      background-color $neuland_gruen
      &:hover
        background-color $neuland_gruen_hover
      *
        color $neuland_offwhite

  &__readmore
    fontSizeSmall()

  @media screen and (max-width $S_width_horizontal)
    display block
    border-top 1px solid $neuland_weiss
    &__item
      min-height auto
      border-bottom 1px solid $neuland_weiss
      position relative
      padding-right $abstand_xxs + 1.7rem
      &:after
        content ""
        position absolute
        right $abstand_xxs
        top calc(50% - 0.75rem)
        width 1.5rem
        height 1.5rem
        background-color $neuland_weiss
        -webkit-mask-image url("/assets/icons/chevron-right.svg")
        mask-image url("/assets/icons/chevron-right.svg")
        mask-repeat no-repeat
        mask-size contain
    h4
      margin-bottom 0

    &__readmore
      display none