.headlineAndContentGrid
  .section
    margin 0
  .constrainedContent
    margin 0
    padding 0
  &__content--rechts, &__content--rechts p
    fontSizeStandard()
  &__content--unten
    margin-top $abstand_l
    .section + section
      margin-top $abstand_xl
    @media screen and (min-width $M_width)
      margin-top $abstand_l

  @media (min-width $XL_width)
    &__head
      display flex
      justify-content space-between
    &__title, &__content--rechts
      flex-basis "calc(50% - (%s * 1/2))" % $abstand_xs
    &__title
      padding-right $abstand_l