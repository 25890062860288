$pswp__show-hide-transition-duration = 333ms 
$pswp__controls-transition-duration = 333ms 
$pswp__background-color = rgba(0,0,0,.95) 
$pswp__placeholder-color = #222 
$pswp__box-sizing-border-box = true  // disable .pswp * { box-sizing:border-box } (in case you already have it in your site css)
$pswp__root-z-index = 1500 
$pswp__assets-path = '../../assets/images/photoswipe/'  // path to skin assets folder (preloader, PNG and SVG sprite)
$pswp__error-text-color = #CCC  // "Image not loaded" text color
$pswp__include-minimal-style = true 

// @import 'photoswipeUi'

.pswp
  display none
  position absolute
  width 100%
  height 100%
  left 0
  top 0
  overflow hidden
  -ms-touch-action none
  touch-action none
  z-index 1500
  -webkit-text-size-adjust 100%
  -webkit-backface-visibility hidden
  outline none
  *
    -webkit-box-sizing border-box
    box-sizing border-box
  img
    max-width none

.pswp--animate_opacity
  opacity 0.001
  will-change opacity
  -webkit-transition opacity 333ms cubic-bezier(0.4, 0, 0.22, 1)
  transition opacity 333ms cubic-bezier(0.4, 0, 0.22, 1)

.pswp--open
  display block

.pswp--zoom-allowed
  .pswp__img
    cursor -webkit-zoom-in
    cursor -moz-zoom-in
    cursor zoom-in

.pswp--zoomed-in
  .pswp__img
    cursor -webkit-grab
    cursor -moz-grab
    cursor grab

.pswp--dragging
  .pswp__img
    cursor -webkit-grabbing
    cursor -moz-grabbing
    cursor grabbing

.pswp__bg
  position absolute
  left 0
  top 0
  width 100%
  height 100%
  background #000
  opacity 0
  transform translateZ(0)
  -webkit-backface-visibility hidden
  will-change opacity
  will-change opacity
  -webkit-transition opacity 333ms cubic-bezier(0.4, 0, 0.22, 1)
  transition opacity 333ms cubic-bezier(0.4, 0, 0.22, 1)

.pswp__scroll-wrap
  position absolute
  left 0
  top 0
  width 100%
  height 100%
  overflow hidden

.pswp__container,
.pswp__zoom-wrap
  -ms-touch-action none
  touch-action none
  position absolute
  left 0
  right 0
  top 0
  bottom 0
  -webkit-backface-visibility hidden

.pswp__container,
.pswp__img
  -webkit-user-select none
  -moz-user-select none
  -ms-user-select none
  user-select none
  -webkit-tap-highlight-color transparent
  -webkit-touch-callout none

.pswp__zoom-wrap
  position absolute
  width 100%
  -webkit-transform-origin left top
  -ms-transform-origin left top
  transform-origin left top
  -webkit-transition -webkit-transform 333ms cubic-bezier(0.4, 0, 0.22, 1)
  transition transform 333ms cubic-bezier(0.4, 0, 0.22, 1)

.pswp--animated-in .pswp__bg,
.pswp--animated-in .pswp__zoom-wrap
  -webkit-transition none
  transition none

.pswp__item
  position absolute
  left 0
  right 0
  top 0
  bottom 0
  overflow hidden

.pswp__img
  position absolute
  width auto
  height auto
  top 0
  left 0

.pswp__img--placeholder
  -webkit-backface-visibility hidden

.pswp__img--placeholder--blank
  background #222

.pswp--ie
  .pswp__img
    width 100% !important
    height auto !important
    left 0
    top 0

.pswp__error-msg
  position absolute
  left 0
  top 50%
  width 100%
  text-align center
  font-size 14px
  line-height 16px
  margin-top -8px
  color #CCC
  a
    color #CCC
    text-decoration underline