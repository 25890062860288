.tags
  vertical-align top
  text-align left
  &__tag
    text-align left
    padding 10px
    border 1px solid $neuland_green
    margin 0 10px 0 0
    background-color rgba(167, 175, 57, 0.12);
    color $neuland_green
    font-weight bold
    border-radius 4px
    margin-bottom 20px
    display inline-block
    font-family $base-font-family
    &:before
      content none !important