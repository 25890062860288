.teasers
  overflow auto
  width 100%
  margin-bottom 40px
  display flex
  @media all and (max-width: 500px)
    flex-wrap wrap
.teasers__teaser 
  @media all and (min-width: 500px)  
    width 50%
    display inline-block
    vertical-align top

.teaser
  padding 30px
  text-align center  
  font-size 20px
  @media screen and (max-width: $L_width)
    font-size 16px
  &__headline
    @extends .formattedText h3
    margin-top 0
    width 100%
    text-align center
  &__caption
    color $base-font-color
  &__image
    padding-bottom 25px
    transition transform .3s
    display block
    text-align center
  &__imageImg
    width 60%
    @media all and (max-width: $M_width)  
      width 80%
        
  &__link
    display block
  &__link:hover
    .teaser__image
      transform scale(1.1)
  
.teasers--uniqueTeaser
  justify-content center
  .teasers__teaser
    @media all and (min-width: 500px) 
      width 80%