@font-face
  font-family 'Macan'
  font-weight normal
  font-style: normal
  src url('../fonts/Macan-Book.otf') format('otf'),
  url('../fonts/Macan-Book.woff') format('woff'),
  url('../fonts/Macan-Book.woff2') format('woff2')
  font-display swap

@font-face
  font-family 'Macan'
  font-weight normal
  font-style: italic
  src url('../fonts/Macan-Book-Italic.otf') format('otf'),
  url('../fonts/Macan-Book-Italic.woff') format('woff'),
  url('../fonts/Macan-Book-Italic.woff2') format('woff2')
  font-display swap

@font-face
  font-family 'Macan'
  font-weight bold
  font-style: normal
  src url('../fonts/Macan-Bold.otf') format('otf'),
  url('../fonts/Macan-Bold.woff') format('woff'),
  url('../fonts/Macan-Bold.woff2') format('woff2')
  font-display swap

@font-face
  font-family 'Macan'
  font-weight bold
  font-style: italic
  src url('../fonts/Macan-Bold-Italic.otf') format('otf'),
  url('../fonts/Macan-Bold-Italic.woff') format('woff'),
  url('../fonts/Macan-Bold-Italic.woff2') format('woff2')
  font-display swap

  /*old version*/
@font-face
  font-family 'TheSans'
  src url('WebFont.eot')
  font-weight normal
  font-style normal
  src url('../fonts/old/TheSans_B2_400_.eot?#iefix') format('embedded-opentype'),
      url('../fonts/old/TheSans_B2_400_.woff') format('woff'),
       url('../fonts/old/TheSans_B2_400_.svg#webfont') format('svg')

@font-face
  font-family 'TheSans'
  src url('WebFont.eot')
  font-weight normal
  font-style italic
  src url('../fonts/old/TheSans_B2_400i.eot?#iefix') format('embedded-opentype'),
  url('../fonts/old/TheSans_B2_400i.woff') format('woff'),
  url('../fonts/old/TheSans_B2_400i.svg#webfont') format('svg')

@font-face
  font-family 'TheSans'
  font-weight bold
  font-style: normal
  src url('../fonts/old/TheSans_B2_800_.eot')
  src url('../fonts/old/TheSans_B2_800_.eot?#iefix') format('embedded-opentype'),
     url('../fonts/old/TheSans_B2_800_.woff') format('woff'),
     url('../fonts/old/TheSans_B2_800_.svg#webfont') format('svg')

@font-face
  font-family 'TheSans'
  font-weight bold
  font-style: italic
  src url('../fonts/old/TheSans_B2_800i.eot')
  src url('../fonts/old/TheSans_B2_800i.eot?#iefix') format('embedded-opentype'),
     url('../fonts/old/TheSans_B2_800i.woff') format('woff'),
     url('../fonts/old/TheSans_B2_800i.svg#webfont') format('svg')

@font-face
  font-family 'TheSansMono'
  font-weight normal
  font-style: normal
  src url('../fonts/old/TheSansMono-M4SemiLight.eot')
  src url('../fonts/old/TheSansMono-M4SemiLight.eot?#iefix') format('embedded-opentype'),
     url('../fonts/old/TheSansMono-M4SemiLight.woff') format('woff'),
     url('../fonts/old/TheSansMono-M4SemiLight.svg#webfont') format('svg')

@import url("//hello.myfonts.net/count/31bd6d");

@font-face
  font-family: 'ThrowMyHandsUpintheAir'
  src: url('../fonts/old/31BD6D_0_0.eot')
  src: url('../fonts/old/31BD6D_0_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/old/31BD6D_0_0.woff2') format('woff2'),
    url('../fonts/old/31BD6D_0_0.woff') format('woff'),

@import url("//hello.myfonts.net/count/32167f");

@font-face
  font-family: 'PonyTalePro'
  src: url('../fonts/old/32167F_0_0.eot')
  src: url('../fonts/old/32167F_0_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/old/32167F_0_0.woff2') format('woff2'),
    url('../fonts/old/32167F_0_0.woff') format('woff'),
