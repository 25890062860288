@require 'imageSource'
.banner
  padding-top $nav_height
  min-height 250px
  position relative
  &--image
    height 50vh
    min-height 200px
    @media screen and (min-width $L_width)
      min-height 450px
    &:after
      display block
      content ""
      width 100%
      height 100%
      position absolute
      top 0
      opacity 0.2
      z-index 1
  &--video
    height 50%
    overflow hidden
    min-height 600px
    @media screen and (max-width: $M_width)
      min-height auto
      height 61.8vh
    @media screen and (max-width: 350px)
      min-height 300px
      height 300px
      
  &__claim
    sideSpacing()
    position absolute
    font-family $base-font-family
    color white
    left 0
    right 0
    bottom $abstand_xs
    text-shadow 2px 3px 7px rgba(0, 0, 0, 0.51)
    text-transform uppercase
    padding-top $abstand_xs
    padding-bottom $abstand_xs
    max-width 100%
    fontSizeXL()
    @media screen and (min-width: $M_width)
      bottom 70px

  &__content
    margin $nav_height 0 0
    left 0
    right 0
    z-index 2
    position absolute
    bottom 0
    @media screen and (max-width: $L_width)
      width auto
      margin-top 0
  &--image
    color white
    text-align left
    text-shadow 2px 3px 7px rgba(0, 0, 0, 0.51)
  &__text
    font-family $base-font-family
    margin 0 20px
    line-height 1.5em
    font-size 24px
    color #666
    @media screen and (max-width: $L_width)
      font-size 20px
    &--padding
      padding 3em 0
      @media screen and (min-width: $L_width)  
        width $L_width  
        margin 0 auto
        padding 3em 20px
        box-sizing border-box
  &--full
    width auto
    
  &__video
    position absolute
    left 0
    top 50%
    transform translateY(-50%)
    min-width 100%
    min-height 100%
    width auto
    height auto
    
  &__source
    @extend .imageSource

.banner__content
  &--xl
    max-width $XL_width