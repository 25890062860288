.cta-zweispaltig
  //padding 1.5rem
  justify-content space-between
  align-items center

  @media screen and (max-width: $M_width)
    &__content
      padding-bottom 1.5rem
  @media screen and (min-width: $M_width)
    display flex
    &__content
      padding-right 1.5rem
      max-width 43.75rem
    &__button
      text-wrap nowrap

