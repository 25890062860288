.layoutRow
  display grid
  grid-template-columns repeat(12, 1fr)
  margin-bottom 3.25rem  //TODO: nach relaunch kommt in die columns.php die klasse section dazu
.layoutColumn
  padding-right 2rem
  &--span-1
    grid-column span 1
  &--span-2
    grid-column span 2
  &--span-3
    grid-column span 3
  &--span-4
    grid-column span 4
  &--span-6
    grid-column span 6
  &--span-8
    grid-column span 8

