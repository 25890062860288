.infiniteSlider
  width 100%
  overflow hidden
  margin-bottom $abstand_xxl
  display flex

  .swiper-wrapper
    transition-timing-function linear

  &__items
    display flex
    width 100%

  &__item
    height 2.25rem
    align-self center
    align-content center
    width auto
    .witt
      padding-bottom 0.5rem
    img
      width auto
      height 1.9rem
      padding-right $abstand_xxl
      object-fit fill
      max-width 9.75rem

.section--customerSlider .infiniteSlider
  margin-top $abstand_l
  @media screen and (min-width $M_width)
    margin-top $abstand_m
    retina({margin-top: $abstand_l})
  @media screen and (min-width $L_width)
    retina({margin-top: $abstand_m})
