.workshopList
  margin 1rem auto
  max-width 1800px
  &__title
    margin-bottom 1rem!important
  &__container
    display flex
    flex-wrap wrap
  //h4
  //line-height 1.5rem
  //margin-bottom 0.5rem!important

.workshopList.formattedText
  h3, h4
    text-align left
    color $neuland_schwarz

.workshop
  flex-basis 100%
  margin-bottom 2rem
  background-color #272727
  margin-right 10px
  padding 16px
  &:last-child
    margin-right 0
  @media (min-width: 501px) and (max-width: 1000px)
    flex-basis calc(50% - 10px)
  @media (min-width: 1001px) and (max-width: 1400px)
    flex-basis calc(33.3% - 10px)
  @media (min-width: 1401px)
    flex-basis calc(25% - 10px)
  @media (max-width: 500px)
    margin-bottom 1rem
    margin-right unset
  &__head
    width 100%
    @media (max-width: 500px)
      display flex
      justify-content space-between
      align-items center
  &__titleAndBookmark
    display flex
    align-items flex-start
    min-height 27px
    margin-bottom 5px
  &__title
    width 95%
  &__infos
    @media (max-width: 500px)
      width 100%
  &__badges
    display flex
    flex-wrap wrap
    align-items baseline
  &__badge
    background $neuland_rotorange
    color #fff
    fontSizeSmall()
    padding 0.1rem 0.5rem 0.1rem 0.3rem
    border-radius 0 100px 100px 0
    margin-right 12px
    margin-bottom 4px
    &:last-child
      margin-right 0
  &__locationnumber
    width 1.3rem
    height 1.3rem
    display inline-block
    color #000
    border-radius 50%
    background-color $neuland_green
    font-weight bold
    text-align center
    fontSizeSmall()
    top 0
    line-height 1.2rem
    font-family $default-font-family
  &__abstract
    margin 8px 0
    fontSizeSmall()
    @media (max-width: 500px)
      &--visible
        display block
        margin-top 5px
  &__moderator
    fontSizeSmall()
    margin-bottom 10px
  &__location
    fontSizeSmall()
    width 100%
    text-align right
  &__bookmark
    margin-left 4px
    cursor pointer
    &--active
      .icon__star--empty
        display none
      .icon__star--full
        display block !important
  .icon__star
    //margin-bottom 2px
    width 22px //24px
    height 22px //24px
    &--empty
      fill #888
    &--full
      fill #9a8c0c
      display none
  .icon__chevronDown
    fill #888
    display none
    &--rotate
      transform rotate(180deg)

.main--tvMode
  min-height 100vh
  display flex
  flex-direction column
  .workshopList
    //margin 0 4rem 3rem
    &--hideAbstract
      .workshop__abstract
        display none
  .workshop
    display flex
    flex-wrap wrap
    &__bookmark
      display none
    &__title
      width unset
    &__location
      align-self flex-end

.main--onlyTimetable
  .workshopList
    display none